.guideline-card {
    padding: 10px;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    text-align: left;
    height: 180px;
    overflow: hidden; /* Prevent overflow of text */
}

.guideline-number {
    background-color: white;
    color: #000;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 15px;
    font-size: 1.2rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    flex-shrink: 0;
}

.guideline-text {
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.96rem;
    line-height: 1.2; 
    flex: 1; 
    overflow: hidden; 

}

/* Color classes */
.orange {
    background-color: #e18d20;
}

.teal {
    background-color: #6bc0b8;
}

/* Custom image styles for better responsiveness */
.custom-img {
  width: 100%;
  height: auto;
  max-width: 800px;
  margin: 0 auto 20px;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .custom-img {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
    .guideline-card {
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: auto; /* Dynamic height for smaller screens */
    }

    .guideline-number {
        margin-bottom: 10px;
        font-size: 1rem;
        width: 40px;
        height: 40px;
    }

    .guideline-text {
        font-size: 0.85rem; /* Slightly smaller text for mobile */
        white-space: normal; /* Allow text to wrap on mobile */
        overflow: visible; /* Show all text on mobile */
        line-height: 1.4; /* Keep line height consistent */
    }
}

@media (max-width: 576px) {
    .custom-img {
        max-width: 100%;
    }
}
