/* .contact {
  background-color: #f8f9fa;
  border-radius: 8px;
  height: 10rem !important;
}

.card-title {
  font-weight: bold;
  color: #333;
}

.card-text {
  font-size: 0.9rem;
}

.text-primary {
  color: #007bff !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.social-icons a {
  text-decoration: none;
}

.social-icons .fs-4 {
  font-size: 1.5rem;
}

.social-icons .mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.social-icons .text-primary {
  color: #db1d04;
}

.social-icons .text-info {
  color: #17a2b8;
}

.social-icons .text-danger {
  color: #dc3545;
} */

/* General Styling */
.contact-container {
  margin-top: 2rem;
}

.contact-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 1.5rem;
  transition: transform 0.3s ease;
  height: 100%;
}

.contact-card:hover {
  transform: scale(1.05);
}

.card-title {
  font-weight: bold;
  color: #333;
}

.card-text {
  font-size: 1rem;
  color: #555;
}

.icon-primary {
  color: #007bff;
}

.social-icons a {
  text-decoration: none;
  margin: 0 0.5rem;
}

.social-icons a:hover {
  transform: scale(1.2);
}

.social-icons .fs-4 {
  font-size: 1.8rem;
}

/* Custom Colors for Social Media Icons */
.social-icons .text-facebook {
  color: #3b5998;
}

.social-icons .text-twitter {
  color: #1da1f2;
}

.social-icons .text-instagram {
  color: #e4405f;
}

.social-icons .text-linkedin {
  color: #0077b5;
}

.social-icons .text-youtube {
  color: #ff0000;
}

.embed-card {
  background-color: #f1f1f1;
  border-radius: 8px;
  transition: all 0.3s;
}

.embed-card:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.col {
  color: #6bc0b8;
}
