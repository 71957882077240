.primary-title {
    font-family: "Tajawal", sans-serif;
    font-size: 2.8rem;
    color: #003366;
    /* Dark blue for a formal look */
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
}

.secondary-title {
    font-family: "Jost", sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 3rem;
}

/* Card layout and hover animations */
.card {
    border-radius: 12px;
    overflow: hidden;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
}

.card-title {
    color: #6bc0b8 !important;
}

.card-hover:hover {
    transform: scale(1.05);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
}

.card h5 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #004080;
    transition: color 0.3s ease;
}

.card p {
    font-size: 0.95rem;
    color: #666;
    transition: color 0.3s ease;
}

.card-hover:hover h5 {
    color: #002b5c;
    /* Darker blue on hover */
}

.card-hover:hover p {
    color: #444;
}

.card-img-top {
    height: 200px;
    object-fit: cover;
    border-bottom: 4px solid #004080;
    transition: all 0.3s ease;
}

.card-hover:hover .card-img-top {
    filter: brightness(0.9);
}

/* Advanced box shadow */
.shadow-lg {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Fade-in animations */
.card {
    opacity: 0;
    transform: translateY(30px);
    animation: fadeIn 0.6s ease forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.card:nth-child(1) {
    animation-delay: 0.1s;
}

.card:nth-child(2) {
    animation-delay: 0.2s;
}

.card:nth-child(3) {
    animation-delay: 0.3s;
}

.card:nth-child(4) {
    animation-delay: 0.4s;
}

/* Button-like interaction on hover */
.card:hover {
    cursor: pointer;
}

/* Responsive enhancements */
@media (max-width: 768px) {
    .primary-title {
        font-size: 2.2rem;
    }

    .secondary-title {
        font-size: 1.4rem;
    }

    .card-img-top {
        height: 160px;
    }

    .card h5 {
        font-size: 1.1rem;
    }

    .card p {
        font-size: 0.85rem;
    }
}