.img-wrapper {
  position: relative;
  overflow: hidden;
}

.card-img-top {
  transition: transform 0.3s ease;
}

.img-wrapper:hover .card-img-top {
  transform: scale(1.05);
  /* Zoom on hover */
}

.title-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #6bc0b8;
  /* Dark background for the title */
  padding: 5px;
  /* z-index: 2; */
}

.title-text {
  color: white !important;
  font-size: 1rem;
  text-align: center;
  /* text-transform: uppercase; */
}

@media (max-width: 576px) {
  .title-text {
    font-size: 1rem;
  }
}