/* .carousel-caption { */
/* text-align: left; */
/* bottom: 10%;
  left: 10%; */
/* top: 250px !important; */
/* transform: translateY(20%); */
/* } */
/* 
.carousel-caption h2 {
  color: #e18d20;
  font-weight: bold;

}

.participate-btn {
  background: linear-gradient(45deg, rgb(255, 98, 0), rgb(255, 144, 0));
  color: #fff;
  font-weight: 600;
  padding: 12px 28px;
  font-size: 1.2rem;
  border-radius: 30px;
  box-shadow: 0 10px 20px rgba(255, 98, 0, 0.3);
  transition: all 0.4s ease;
  margin-top: 20px;
} */
/* 
.participate-btn:hover {
  background: linear-gradient(45deg, rgb(0, 217, 255), rgb(0, 162, 255));
  transform: scale(1.05);
}

.carousel-item {
  cursor: pointer;
} */
/* 
@media (max-width: 992px) {
  .navbar .nav-link {
    padding: 8px 0;
    font-size: 15px;
  } */

/* .social-media {
    display: none; 
  } */

/* .contact-info {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .carousel-caption {
    top: 50px !important;

  }

  .carousel-caption h4 {
    font-size: 10px;

  }

  .carousel-caption p {
    font-size: 10px;

  }

  .carousel-caption button {
    font-size: 10px;

  }
} */

/* Adjust carousel-caption top position for responsiveness */
/* Adjust carousel-caption top position for responsiveness */
.carousel-caption {
  display: flex;
  /* Ensure the caption uses flexbox */
  flex-direction: column;
  /* Stack elements vertically */
  justify-content: center;
  /* Center content vertically */
  align-items: center;
  /* Center content horizontally */
  top: 20px !important;
  /* Adjust top position */
}

/* Responsive Button */


/* Button hover effect */
.participate-btn:hover {
  background-color: #6bc0b8 !important;
  color: rgb(255, 252, 252) !important;
}

/* Adjust button for different screen sizes */

/* Large screens */
@media (max-width: 1200px) {
  .participate-btn {
    padding: 8px 18px;
    font-size: 1.2rem;
  }
}

/* Medium screens */
@media (max-width: 768px) {
  .carousel-caption {
    top: 30px !important;
    /* Keep top fixed at 20px */
  }

  .participate-btn {
    padding: 6px 16px;
    font-size: 1rem;
  }
}

/* Small screens */
@media (max-width: 768px) {
  .carousel-caption {
    top: 30px !important;
    /* Keep top fixed at 20px */
  }

  .participate-btn {
    padding: 4px 10px;
    font-size: 0.9rem;
  }
}

/* Small screens */
@media (max-width: 768px) {
  .carousel-caption {
    top: 30px !important;
    /* Keep top fixed at 20px */
  }

  .participate-btn {
    padding: 4px 10px;
    font-size: 0.9rem;
    width: 180px;
    font-size: 10px;
  }
}

/* Extra small screens */
@media (max-width: 576px) {
  .carousel-caption {
    top: 50px !important;
    /* Keep top fixed at 20px */
  }

  .participate-btn {
    padding: 2px 6px;
    font-size: 0.7rem;
  }
}