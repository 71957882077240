/* Container */
.container {
  /* Gradient background */
  /* padding: 40px 0; */
  /* margin-top: 20px; */
  margin-bottom: 20px;
}

/* Primary Title */
.primary-title {
  color: #f77104;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
  transition: color 0.3s ease, transform 0.3s ease;
  position: relative;
  display: inline-block;
}

.primary-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 4px;
  background: #f77104;
  border-radius: 2px;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.primary-title:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.primary-title:hover {
  color: #e56b00;
  /* Darker shade on hover */
  transform: scale(1.05);
  /* Slight zoom effect */
}

/* Secondary Title */
.secondary-title {
  color: #6bc0b8;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.secondary-title:hover {
  color: #068f94;
  /* Slightly darker shade on hover */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  /* Subtle shadow on hover */
}

/* Card Styling */
.card {
  border-radius: 10px;
  /* Rounded corners */
  overflow: hidden;
  /* Clean edges */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  position: relative;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  /* Subtle overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card:hover::before {
  /* opacity: 1; */
}

.card:hover {
  transform: translateY(-10px);
  /* Lift effect */
  box-shadow: 0 12px 12 rgba(0, 0, 0, 0.2);
  /* Enhanced shadow */
}

.card-img-top {
  border-bottom: 4px solid #f77104;
  /* Colorful border */
  transition: transform 0.3s ease, filter 0.3s ease;
}

.card-img-top:hover {
  transform: scale(1.1);
  /* Zoom effect */
  filter: brightness(80%);
  /* Darker image on hover */
}

.card-body {
  padding: 20px;
  position: relative;
}

.card-body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #f77104;
  border-radius: 2px;
  transform: scaleX(0);
  transform-origin: top right;
  transition: transform 0.3s ease;
}

.card-body:hover::after {
  transform: scaleX(1);
  transform-origin: top left;
}

.card-title {
  font-size: 1.25rem;
  /* font-weight: 600; */
  color: #333;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  /* Improved readability */
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 15px;
}