.prize-card {
  background-color: #e18d20;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  /* Centers items horizontally */
  align-items: center;
  /* Centers items vertically */
  text-align: center;
}

.rank-circle {
  background-color: white;
  color: #6bc0b8;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  flex-shrink: 0;
}

.prize-amount {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
}

.custom-img {
  max-width: 80%;
  /* Adjusts the image to 80% of its container's width */
  height: auto;
  /* Ensures the aspect ratio is maintained */
}

@media (max-width: 992px) {
  .custom-img {
    max-width: 90%;
    /* Slightly larger for tablet screens */
  }
}

@media (max-width: 768px) {
  .custom-img {
    max-width: 100%;
    /* Full width for mobile devices */
  }
}

@media (max-width: 768px) {
  .prize-card {
    flex-direction: column;
    padding: 15px;
  }

  .rank-circle {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .prize-amount {
    font-size: 1.5rem;
  }
}