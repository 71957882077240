@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
}

/* Navbar Styling */
.navbar {
  background-color: #fff !important;
  border-bottom: 2px solid #f1f1f1;
  padding: 15px 0;
  transition: background-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
}

.navbar:hover {
  background-color: #f9f9f9;
  /* Slightly darker background on hover */
  border-bottom: 2px solid #e0e0e0;
}

/* Logo Section */
.navbar-brand img {
  /* max-width: 100px; */
  /* transition: transform 0.3s ease-in-out; */
}

.navbar-brand img:hover {
  /* transform: scale(1.1); */
  /* Slight zoom effect on hover */
}

/* Heading and Subtitle Styling */
.name {
  font-size: 30px;
  font-weight: 700;
  color: #e18d20;
  transition: color 0.3s ease-in-out;
}

.name:hover {
  color: #e18d20;
  /* Slight color change on hover */
}

/* Navigation Links */
.navbar .nav-link {
  font-weight: 700;
  font-size: 15px;
  color: #e18d20 !important;
  padding: 10px 20px;
  margin: 0 10px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-radius 0.3s ease-in-out;
}

.navbar .nav-link:hover {
  color: #6bc0b8 !important;
  /* background-color: #f0f0f0; */
  /* Light background on hover */
  /* border-radius: 5px; */
  /* Rounded corners on hover */
  transform: scale(1.02);
  /* Slight zoom effect */
}

/* Active Link */
.active {
  border-bottom: 2px solid #e18d20 !important;
}

/* Social Media Icons */
.social-media ul li {
  display: inline-block;
  height: 35px;
  width: 35px;
  background: #6bc0b8;
  color: #fff;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  margin: 5px 5px 0 0;
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.social-media ul li:hover {
  background-color: #e18d20;
  transform: scale(1.1);
  /* Slight zoom effect on hover */
}

/* Email and Phone */
.contact-info {
  font-size: 14px;
  color: #fff;
  transition: font-size 0.3s ease-in-out;
}

.contact-info a {
  color: #002897;
  transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
  text-decoration: none;
  font-weight: 500;
  /* margin-left: 25px; */
}

.contact-info a:hover {
  color: #e18d20;
  text-decoration: underline;
  /* Underline on hover */
}

/* Dropdown Menu Styling */
.navbar-nav .nav-item.dropdown .dropdown-menu {
  background-color: #fff;
  color: #0c1e33;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.navbar-nav .nav-item.dropdown .dropdown-menu:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item {
  padding-left: 20px;
  color: #0c1e33;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #e18d20;
  color: #fff;
  transform: translateX(5px);
  /* Slight shift on hover */
}

/* Responsive Styling */
@media (max-width: 992px) {
  .navbar .nav-link {
    padding: 8px 0;
    font-size: 15px;
  }

  /* .social-media {
    display: none; 
  } */

  .contact-info {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .name {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }

  /* 
  .navbar-brand img {
    max-width: 80px;
  } */
}
