/* src/ScrollingAnnouncement.css */
.announcement {
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    color: #e18d20;
}

.gif {
    width: 50px; /* Adjust size as needed */
    height: auto;
    margin-right: 10px;
}
