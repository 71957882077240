@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: "Poppins", system-ui;
  font-style: normal;
}

.col1 {
  font-weight: 700;
  font-size: 30px;
  color: #e18d20;
  text-transform: uppercase;
}

.button {
  background-color: #e18d20 !important;
  color: white !important;
}

.backgroundcolor {
  background-color: #6bc0b8;
}